@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.woff") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "InterMedium";
  src: local("Inter"), url("./fonts/Inter-Medium.woff") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "InterBold";
  src: local("Staatliches"), url("./fonts/Inter-Bold.woff") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "InterSemiBold";
  src: local("Staatliches"),
    url("./fonts/Inter-SemiBold.woff") format("truetype");
  font-weight: bold;
}

body,
html,
div,
p {
  font-family: "Inter";
  font-feature-settings: "pnum" on, "lnum" on;
}

.font-inter {
  font-family: "Inter";
  font-feature-settings: "pnum" on, "lnum" on;
}

.font-inter-medium {
  font-family: "InterMedium";
  font-feature-settings: "pnum" on, "lnum" on;
}

.font-inter-bold {
  font-feature-settings: "pnum" on, "lnum" on;
  font-family: "InterBold";
}

.font-inter-semibold {
  font-feature-settings: "pnum" on, "lnum" on;
  font-family: "InterSemiBold";
}

.text-shadow {
  text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.26);
}

.react-button:hover {
  color: #cc9f46;
}

.react-button:active {
  filter: brightness(0.8);
}

.react-text {
  color: rgba(255, 255, 255, 0.6);
  transition-duration: 0.3s;
}
.react-text:hover {
  color: white;
}
.react-text:active {
  color: rgba(255, 255, 255, 0.2);
}

.react-tab {
  opacity: 0.38;
  transition-duration: 0.3s;
}
.react-tab:hover {
  opacity: 1;
}
.react-tab:active {
  opacity: 0.1;
}
.react-social {
  filter: grayscale(0.5);
  transition-duration: 0.2s;
}
.react-social:hover {
  filter: grayscale(0);
  /* transform:translateX(-10px); */
  z-index: 1000;
  transform: scale(1.2) translateX(-5px);
}

.expand {
  /*background-size: cover;*/
  background-repeat: no-repeat;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s linear;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes earn-appear-animation {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes smoke-appear-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes story-appear-animation {
  0% {
    opacity: 0;
    transform: translateY(180px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes how-to-earn-appear-animation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(-20px);
  }
}
@keyframes team-appear-animation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.story-appear-anim {
  animation: story-appear-animation 0.75s ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
.team-appear-anim {
  animation: team-appear-animation 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
.smoke-appear {
  animation: smoke-appear-animation 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.anim-delay-1 {
  animation-delay: 0.25s;
}
.anim-delay-1-fast {
  animation-delay: 0.1s;
}
.anim-delay-2 {
  animation-delay: 0.5s;
}
.anim-delay-2-fast {
  animation-delay: 0.15s;
}
.anim-delay-3 {
  animation-delay: 0.75s;
}
.anim-delay-3-fast {
  animation-delay: 0.2s;
}
.anim-delay-4 {
  animation-delay: 1s;
}
.anim-delay-5 {
  animation-delay: 1.25s;
}

.expand-mobile {
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s linear;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 320px; */
  /* background-color: #555; */
  /* color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 69%;
  left: 117%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.textGradient {
  background: #ffef9b;
  background: -webkit-linear-gradient(
    to bottom,
    #ffef9b 20%,
    #ffd78a 40%,
    #ffbe40 60%,
    #ffeecc 80%,
    #ffdfb9 100%
  );
  background: -moz-linear-gradient(
    to bottom,
    #ffef9b 20%,
    #ffd78a 40%,
    #ffbe40 60%,
    #ffeecc 80%,
    #ffdfb9 100%
  );
  background: linear-gradient(
    to bottom,
    #ffef9b 20%,
    #ffd78a 40%,
    #ffbe40 60%,
    #ffeecc 80%,
    #ffdfb9 100%
  );
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.textEvent {
  background: #37221a;
  /* background: #816212; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-feature-settings: "pnum" on, "lnum" on;
  /* background: linear-gradient(95.81deg, #c2a250 6.97%, #d0b777 43.6%, #dfc993 67.36%, #f1ca65 102.01%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.header-item {
  padding: 16px 10px;
}

.uppercase {
  text-transform: uppercase !important;
}

.self-center {
  align-self: center;
}

.fill-black {
  filter: gray saturate(0%) brightness(70%) contrast(1000%);
}

.wrap {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.text-yellow {
  color: #f8e47e;
}

.top-to-bottom-anim {
  animation-name: top-bottom;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes top-bottom {
  0% {
    transform: translateY(-80px);
    opacity: 0;
  }

  20% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.text-12px {
  font-size: 12px;
  line-height: 20px;
}

.text-26px {
  font-size: 26px;
  line-height: 36px;
}

.text-straight {
  font-feature-settings: "pnum" on, "lnum" on;
}

.tooltip-container {
  transform: translateX(20%) translateY(-20%);
}

.tooltip-container-mb {
  transform: translateX(-28%) translateY(-20%);
}

.tooltip-container-mb-right {
  transform: translateX(-93%) translateY(-20%);
}

.absolute-center {
  left: 50%;
  transform: translate(-50%, 0);
}

@keyframes up-down {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(8px);
  }
  75% {
    transform: translateY(12px);
  }
  100% {
    transform: translateY(16px);
  }
}

.up-down-anim {
  animation-name: up-down;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.shadow {
  background-size: 100% 180vh;
  box-shadow: 0px 0px 10px 10px red;
}

.image-filter:hover img {
  filter: brightness(1.2);
}
.bg-map-dashboard {
  background: rgba(0, 0, 0, 0.77) !important;
  border: 2px solid #5d4418;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.57),
    inset 0px 0px 8px rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

area:-webkit-any-link {
  cursor: pointer;
}

#overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  display: none;
}

#pop-up {
  background-color: white;
  border: 2px solid black;
  display: block;
  width: 350px;
  z-index: 1001;
  top: 60px;
  left: 240px;
  position: fixed;
  padding-left: 10px;
  margin: auto;
}
.bg-banner-pos {
  background-position-x: 18% !important;
}

.wrapper {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  background: #f7f7f7;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -250px;
}
.mb-description {
  height: calc(69vh - 260px) !important;
}

.hr {
  /* border: #000000 solid 1px; */
  border-width: solid;
  border-width: 1px;
}

.text-btn {
  text-shadow: -1px -1px 0 #000000, 0 -1px 0 #000000, 1px -1px 0 #000000,
    1px 0 0 #000000, 1px 1px 0 #000000, 0 1px 0 #000000, -1px 1px 0 #000000,
    -1px 0 0 #000000;
  font-style: normal;
  font-weight: 700;
  -webkit-text-fill-color: white;
}

.text-stroke {
  text-shadow: -0.5px -0.5px 0 #000000, 0 -0.5px 0 #000000,
    0.5px -0.5px 0 #000000, 0.5px 0 0 #000000, 0.5px 0.5px 0 #000000,
    0 0.5px 0 #000000, -0.5px 0.5px 0 #000000, -0.5px 0 0 #000000;
  font-style: normal;
  font-weight: 700;
  -webkit-text-fill-color: white;
}

.section-container {
  padding-left: 10rem;
  padding-right: 10rem;
  z-index: 99;
}

@media only screen and (max-width: 600px) {
  .section-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.card-container {
  box-shadow: 0px 6px 30px #bfbfbf, 0px 6px 16px #bfbfbf !important;
}

.dropdown-content {
  background-color: #f9f9f9;
  min-width: 100vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown {
  display: inline-block;
}

.popup-content {
  max-width: 500px;
  width: 80% !important;
}

.link-verticle {
  width: 10px;
  background: black;
  height: 140px;
}

.left-link {
  left: calc(50% + 40px);
}

.right-link {
  left: calc(50% - 40px);
  transform: translateX(-100%);
}

.milestone-container-left {
  box-shadow: 0px 5px 5px #000000;
  transform: translateX(100%);
  left: -66px;
}
.milestone-container-right {
  box-shadow: 0px 5px 5px #000000;
  transform: translateX(-100%);
}

i.flag:not(.icon):before {
  height: 11px !important;
}

.ui.dropdown > .dropdown.icon {
  height: 0 !important;
  display: hidden;
}

.ui.dropdown > .dropdown.icon:before {
  height: 0 !important;
  display: hidden;
}

.ui.dropdown {
  height: 30px;
}

.carousel.carousel-slider .control-arrow {
  background-color: black !important;
}

.bg-product-0 {
  background: #c1302a;
  width: 100%;
  justify-content: center;
  align-self: center;
}

.bg-product-1 {
  background: #d9d9d9;
  width: 100%;
  justify-content: center;
  align-self: center;
}

.partner img {
  border-radius: 10px;
}

.bg-card {
  background: #141416;
}

.ui.dropdown > .dropdown.icon:before {
  opacity: 0;
}

.card-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.text-link {
  color: #0088ff;
}

.category {
  padding: 8px 18px;
  border-bottom: 1px solid #d3d5d7;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #f5f5f5;
}

.cate-level2 {
  padding: 8px 17px;
  font-size: 14px;
}

.big-cate:after {
  content: "";
}

.big-cate {
  font-size: 15px;
  font-family: "InterBold";
}

.category:hover:not(.active-category) {
  /* background: #446cb3; */
  color: #446cb3;
}

.card-client {
  box-shadow: 0px 0.5px 6px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  /* transform: scale(1.1); */
  transform: scale(0.85);
}

.carousel.carousel-slider {
  height: 100%;
}

.carousel .slider-wrapper {
  height: 100%;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  height: 100%;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide div {
  height: 100%;
}

.product-img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.product-container .control-product {
  opacity: 0;
}
.product-container:hover .control-product {
  opacity: 1;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.float-anim {
  animation: float 3s ease-in-out infinite;
}

.active-category {
  background: #456fa9;
  color: white;
  border-radius: 2px;
}
s .active-img-culture {
  border: 3px solid black;
  opacity: 100;
}

.menu-item {
  color: white;
  font-family: "InterBold";
  font-size: 15px;
  text-transform: uppercase;
}

.menu-item-container:hover {
  background-color: #ea4d3e !important;
  cursor: pointer;
}

.ui.menu > .item:first-child {
  border-radius: 0px;
}

.main-container {
  max-width: 1460px;
  margin: auto;
}

@media only screen and (max-width: 1600px) {
  .main-container {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media only screen and (max-width: 600px) {
  .main-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .menu-item {
    padding: 6px 0;
  }
}

.ui.menu .active.item {
  background-color: #ea4d3e;
}

.bg-demo {
  border: 2.5px solid #cecece;
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-demo-child {
  width: 100%;
  background-color: white;
}

.typo-xl {
  text-transform: uppercase;
  font-family: "InterBold";
  font-size: 17px;
  margin-top: 10px;
}

.typo-l {
  margin-top: 10px;
  text-transform: uppercase;
  font-family: "InterBold";
  font-size: 16.5px;
}

.imgonly {
  max-width: 550px;
  margin: 20px auto;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  width: 80%;
}

.imgonly img {
  width: 100%;
}

.img-container {
  text-align: center;
  margin: 20px auto;
  align-items: center;
  align-self: center;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  max-width: 550px;
  justify-content: center;
  max-height: 400px;
}

.img-container img {
  object-fit: cover;
  max-height: 400px;
  height: 25vw;
}

.introduce-container {
  font-size: 16px;
}
